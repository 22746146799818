var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "justify-center row" }, [
      _c(
        "div",
        { staticClass: "col-12 col-md-8 q-mr-sm" },
        [
          _c("QQElencoDocumentiPratica", {
            attrs: {
              title: "",
              tabIndex: "0",
              showContratti: "",
              id_pratica: _vm.IDPratica,
              formPratica: _vm.formPratica,
            },
          }),
        ],
        1
      ),
      _vm._m(1),
      _c("div", { staticClass: "row col-12 col-md-12 justify-center" }, [
        _c(
          "div",
          { staticClass: "col-12 col-md-6", attrs: { align: "center" } },
          [
            _c("QQButton", {
              attrs: {
                label: "TORNA AL MENU",
                color: "blue-grey",
                icon: "undo",
                size: "md",
                icon_align: "left",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onIndietroClicked()
                },
              },
            }),
          ],
          1
        ),
      ]),
    ]),
    _c("br"),
    _c("br"),
    _c("br"),
    _c("br"),
    _c("br"),
    _c("br"),
    _c("br"),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { align: "center" } }, [
      _c("span", { staticClass: "text-h5" }, [
        _vm._v("Documenti associati ai Contratti"),
      ]),
      _c("hr"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 col-md-12" }, [_c("hr")])
  },
]
render._withStripped = true

export { render, staticRenderFns }